<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Which of the following statements regarding the principles of thin-layer chromatography
        (TLC) are true?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA7_Q1',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'Separation will be successful if one component adheres to the stationary phase more than the other component',
          value: 'stationaryPhase',
        },
        {
          text: 'The stationary phase will always be more polar than the mobile phase.',
          value: 'morePolar',
        },
        {
          text: 'Higher concentrations make analyzing the plate easier',
          value: 'concentration',
        },
        {
          text: 'The solvent moves by capillary action.',
          value: 'capillaryAction',
        },
        {
          text: 'If the concentration of the component is too high, the spot will smear or begin to tail.',
          value: 'smear',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
